import React from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from "gatsby-plugin-react-i18next";
import { graphql } from "gatsby";

import Layout from "../../templates/layout";
// import ProductHeader from "../../components/Products/Header";
import Detail from "../../components/Market/Detail";
import { MARKET } from "../../constants";

const CurrentIndex = 1;

const MarketDetail = () => {
  const { t } = useTranslation();
  const marketName = t(`dataMarkets.${MARKET[CurrentIndex].linkDetail}.name`);
  const marketDesc = t(`dataMarkets.${MARKET[CurrentIndex].linkDetail}.desc`);

  return (
    <>
      <Helmet>
        <title>{`${marketName} | ${t("meta.title")}`}</title>
        <meta content={marketDesc} name="description" />
        <meta name="robots" content="index, follow" />
      </Helmet>
      <Layout
        currentLink="market/retail-and-supermarket-chains"
        description={marketDesc}
      >
        <>
          {/* <ProductHeader
          title={marketName}
          linkTitle={marketName
            .replaceAll(" ", "-")
            .toLowerCase()}
          isMarket
        /> */}
          <Detail
            title={marketName}
            desc={marketDesc}
            bgType={MARKET[CurrentIndex].classBg}
          />
        </>
      </Layout>
    </>
  );
};

export default MarketDetail;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
